import React from "react";
import { Navbar } from "../Navbar/Navbar";
import Internal from "../../images/CommunicationIcons/Internal.png";
import CRM from "../../images/CommunicationIcons/Crm.png";
import Payment from "../../images/CommunicationIcons/Payment.svg";
import Orientation from "../../images/CommunicationIcons/Orientation.png";
import Web from "../../images/CommunicationIcons/Web.png";
import Drive from "../../images/CommunicationIcons/Drive.png";
import PT from "../../images/CommunicationIcons/PT.svg";
import Org from "../../images/CommunicationIcons/Org.png";
import Eval from "../../images/CommunicationIcons/Eval.png";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommunicationImage from "../../images/CommIllustration.png";
import decore from "../../images/Decoration.png";

export function Communication() {
  const handleButton = () => {
    window.open("/PaymentRequest", "_blank");
  };

  return (
    <>
      <Navbar />

      <div className="App">
        <Container>
          {/* Title */}
          <Row>
            <Col>
              <h1 className="text-center titleCommunication pb-5">
                Communication Tools
              </h1>
            </Col>
          </Row>

          {/* Main Section */}
          <Row className="CommSection align-items-center">
            {/* Left Column - Image */}
            <Col md={6} className="CommImage p-5">
              <img className="w-100 " src={CommunicationImage} alt="Communication Illustration" />
            </Col>

            {/* Right Column - Buttons */}
            <Col md={6} className="text-center">
              <Row>
                {/* First Set of Buttons */}
                <Col xs={12} md={6}>
                  <Link to="https://app.clickup.com/login" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Internal} alt="Internal" className="px-2" />
                      Internal System
                    </button>
                  </Link>
                  <Link to="https://app.pipedrive.com/auth/login" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={CRM} alt="CRM" className="px-2" />
                      CRM System
                    </button>
                  </Link>
                  <button onClick={handleButton} className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                    <img src={Payment} alt="Payment Request" className="px-2" />
                    Payment Request
                  </button>
                  <Link to="https://drive.google.com/drive/folders/1Gwi5jwipv7YQ5YHyJtI9LASw1Rx8LU11?usp=sharing" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Orientation} alt="Orientation" className="px-2" />
                      Orevan Assets
                    </button>
                  </Link>
                </Col>

                {/* Second Set of Buttons */}
                <Col xs={12} md={6}>
                  <Link to="https://orevan.org/" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Web} alt="Website" className="px-2" />
                      Orevan Website
                    </button>
                  </Link>
                  <Link to="https://drive.google.com/drive/u/0/shared-drives" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Drive} alt="Drive Workspace" className="px-2" />
                      Drive Workspace
                    </button>
                  </Link>
                  <Link to="https://orvihr.orevan-prox.com" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={PT} alt="PT System" className="px-2" />
                      PT System
                    </button>
                  </Link>
                  <Link to="https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&locale=en-ROW" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Org} alt="OT System" className="px-2" />
                      OT System
                    </button>
                  </Link>
                  
                </Col>
                <Link to="https://evaluation.orevan-prox.com" target="_blank" style={{textDecoration:"none"}}>
                    <button className="btn btn-block2 mb-2 py-2 text-white buttonTitle" style={{ backgroundColor: "#948af4" }}>
                      <img src={Eval} alt="OT System" className="px-2" />
                      Performance Management System
                    </button>
                  </Link>
              </Row>
            </Col>
          </Row>

          {/* Decorative Image */}
          <Row>
            <Col>
              <img className="w-100 img-fluid" src={decore} alt="Decoration" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
