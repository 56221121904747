import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import homeImage from "../../images/Banner.png";

export function PaymentRequest() {
  return (
    <>
      <Navbar />
      <div className="App">
        <div className="row pb-5 pt-5">
          <h1>Request a Payment</h1>
        </div>
        <div className="container-fluid">
          <div className="Payment">
            <iframe
              class="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/4546869/f/4ar9n-19055/UYY3HKSL5A1EM2LSCS"
              onwheel=""
              width="100%"
              height="100%"
              style={{ background: "transparent", border: "1px solid #ccc" }}
            ></iframe>
            <script
              async
              src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
            ></script>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Link to="/Communication">
              <button className="btn btn-secondary my-4 p-3 ">
                Return Back
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

/* <iframe
              class="clickup-embed clickup-dynamic-height"
              src="https://forms.clickup.com/4546869/f/4ar9n-17995/M3TXCOFRT8WZNDU56R"
              onwheel=""
              width="100%"
              height="100%"
            ></iframe> */
